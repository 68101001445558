<template>
    <modal-lateral ref="modalChatearAsesor" titulo="Asesor de leeche">
        chat con asesor
    </modal-lateral>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    methods: {
        toggle(){
            this.$refs.modalChatearAsesor.toggle();
        }
    }
}
</script>
